const path = require('path')

module.exports = {
  i18n: {
    locales:       ['en', 'de'],
    defaultLocale: 'de',
  },

  /* async rewrites() {
    return [
      {
        source:      '/de/unternehmen',
        destination: '/de/about',
        locale:      false, // Use `locale: false` so that the prefix matches the desired locale correctly
      },
    ]
  }, */
  localePath:
    typeof window === 'undefined'
      ? path.resolve('./public/locales')
      : '/locales',

}
